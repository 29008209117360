<template>
  <Sidebar id="shopping-cart" v-model:visible="showCart" position="right">
    <div class="peppes-cart-container">
      <p class="cart-title text-left w-full">{{ $t("Yourorder") }}</p>
      <ShoppingCartItemContainer />
      <ShoppingCartSlider />
      <ShoppingCartCheckoutButton
        :cart-length="cartLength"
        :cart-total-price="cartTotalPrice"
        @click="procideToCheckOut"
      />
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { useLocalePath } from "#i18n";
import { useCartSlice } from "~/store/cartSlice";
import { useAuthSlice } from "~/store/authSlice";

const localePath = useLocalePath();
const authStore = useAuthSlice();
const { isAuthenticated } = storeToRefs(authStore);

const cartStore = useCartSlice();
const { showCart, cartLength, cartTotalPrice } = storeToRefs(cartStore);
const procideToCheckOut = async () => {
  if (isAuthenticated.value) {
    cartStore.toggleCartShow();
    console.log(cartLength.value);
    useNuxtApp().$firebaseTrackingFunctions.trackBeginCheckout(
      cartTotalPrice.value,
      cartLength.value,
    );
    await navigateTo({ path: localePath("/checkout") });
  } else {
    cartStore.toggleCartShow();
    authStore.toggleAuthModal();
  }
};
</script>
<style scoped lang="scss"></style>
