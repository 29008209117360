<template>
  <div class="w-full flex flex-column justify-content-center align-content-center gap-3">
    <Button
      :label="$t('ContinuewithSMS')"
      class="border-round login-button"
      @click="handleAuthWithPhoneNumber"
    />
  </div>
</template>

<script setup lang="ts">
import useVippsAuth from "~/composables/auth/useVippsAuth";
import { useAuthSlice } from "~/store/authSlice";
const { signIn } = useVippsAuth();
const authStore = useAuthSlice();
const handleAuthWithPhoneNumber = () => {
  useNuxtApp().$firebaseTrackingFunctions.trackLogin("attempt");
  authStore.toggleAuthModal();
  signIn(true);
};
</script>

<style scoped></style>
