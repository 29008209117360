import { type Ref } from "vue";
import { useMenuSlice } from "~/store/menuSlice";
import peppesMapPin from "~/assets/logo/mapPin.svg";
import { OrderType, type RestaurantType } from "~/types/entities/restaurant";
import { useLocationSlice } from "~/store/locationSlice";
import { isRestaurantOPenNow } from "~/utils/dateTime.util";

interface InfoWindowRef {
  code: string;
  infoWindow: google.maps.InfoWindow;
  marker: google.maps.Marker;
}

/**
 * Provides functionality for interacting with Google Maps.
 * @returns {{
 *   initializeGoogleMap: (googleMapHtml: Ref<HTMLElement | undefined>) => void,
 *   centerMapTo: (coordinates: google.maps.LatLngLiteral) => void
 * }} Object containing functions for initializing and interacting with Google Maps.
 */
export default function useGoogleMap(
  btnClickFn: (e: MouseEvent, i: RestaurantType | null) => void,
) {
  const { t } = useI18n();
  const locationStore = useLocationSlice();
  const menuStore = useMenuSlice();
  const { userLocation } = storeToRefs(locationStore);
  const { restaurants } = storeToRefs(menuStore);
  const buttons = ref<HTMLButtonElement[]>([]);
  const _item = ref<RestaurantType | null>(null);
  let currentOpennedInfoWindow: google.maps.InfoWindow | null = null;
  const infoWindowReferences: InfoWindowRef[] = [];
  let elementRef: HTMLElement | null = null;

  const _btnClickFn = (event: MouseEvent) => {
    btnClickFn(event, _item.value);
    _item.value = null;
  };

  let googleMap: google.maps.Map;

  // Constants
  const mapViewPosition: google.maps.LatLngLiteral = {
    // lat: userLocation.value?.lat || 60,
    // lng: userLocation.value?.lng || 10,
    lat: userLocation.value?.lat || 59.9139,
    lng: userLocation.value?.lng || 10.7522,
  }; // Map view starting position
  const recenterMapToInfoWindow = () => {
    if (currentOpennedInfoWindow) {
      // Get the position of the currently open info window
      const infoWindowPosition = currentOpennedInfoWindow.getPosition();

      // Check if the info window position is valid
      if (infoWindowPosition) {
        // Set the map's center to the position of the info window
        googleMap.panTo(infoWindowPosition);
      } else {
        console.error("Info window position is not valid.");
      }
    } else {
      console.error("No info window is currently open.");
    }
  };
  const infoFiller = (item: RestaurantType) => {
    const uniqueID = `${item.longitude}--${item.latitude}`.replaceAll(".", "-");
    setTimeout(() => {
      const uniqueElement = document.querySelector(`#_${uniqueID}`);

      if (!uniqueElement) {
        return;
      }
      if (elementRef) {
        const isOpen = isRestaurantOPenNow(item);
        elementRef.querySelector("span.status-icon").classList.remove("offline");
        elementRef.querySelector("span.status-icon").classList.remove("online");
        if (isOpen) {
          elementRef.querySelector("span.status-icon").classList.add("online");
          elementRef.querySelector("p.status").innerHTML = t("restaurantStatusOpen");
        } else {
          elementRef.querySelector("span.status-icon").classList.add("offline");
          elementRef.querySelector("p.status").innerHTML = t("restaurantStatusClose");
        }
        elementRef.querySelector("p.title").innerHTML = item.name;
        elementRef.querySelector("p.subtitle").innerHTML =
          "Åpent " +
          item.orderTypeInfo
            .find((i) => i.type === OrderType.TAKEAWAY)
            ?.openingHours.openingTime.split(":")
            .filter((_i, ind) => ind < 2)
            .join(":") +
          "-" +
          item.orderTypeInfo
            .find((i) => i.type === OrderType.TAKEAWAY)
            ?.openingHours.closingTime.split(":")
            .filter((_i, ind) => ind < 2)
            .join(":");
        uniqueElement.innerHTML = elementRef.outerHTML;
      }
      recenterMapToInfoWindow();

      const button = uniqueElement.querySelector("button");
      if (!button) {
        return;
      }

      _item.value = item;

      button.addEventListener("click", _btnClickFn);

      buttons.value.push(button);
    }, 200);
  };

  /**
     * Function initializes new Google Map
     // eslint-disable-next-line jsdoc/require-param-type
     * @param googleMapHtml -htm div reference
     */
  const initializeGoogleMap = (
    googleMapHtml: Ref<HTMLElement | undefined>,
    element: HTMLElement,
  ): void => {
    elementRef = element;
    // Initialize Google Map
    googleMap = new google.maps.Map(googleMapHtml.value as HTMLElement, {
      center: { ...mapViewPosition },
      zoom: 15,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
      clickableIcons: false,
      streetViewControl: false,
      mapTypeControl: false,
      draggableCursor: "crosshair",
      fullscreenControl: false,
      minZoom: 0,
      restriction: {
        latLngBounds: {
          north: 85,
          south: -85,
          west: -180,
          east: 180,
        },
      },

      gestureHandling: "greedy", // Does not need 2 fingers to move on map when using touchscreen (Not working on Firefox Mobile. Safari, not sure.)
      keyboardShortcuts: false,
    });

    (() =>
      new google.maps.Marker({
        position: mapViewPosition,
        map: googleMap,
        title: "Your Location",
      }))();
    if (restaurants.value.length) {
      const icon = {
        url: peppesMapPin,
        size: new google.maps.Size(100, 100),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(35, 45),
      };

      restaurants.value.forEach((item) => {
        const uniqueID = `${item.longitude}--${item.latitude}`.replaceAll(".", "-");
        const contentWrapper = computed(() => `<div id='_${uniqueID}'></div>`);
        if (item.latitude && item.longitude) {
          const infowindow = new google.maps.InfoWindow({
            content: contentWrapper.value,
          });
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.latitude, item.longitude),
            animation: google.maps.Animation.DROP,
            map: googleMap,
            icon,
            title: item.name,
          });
          infoWindowReferences.push({
            code: item.code,
            infoWindow: infowindow,
            marker,
          });
          marker.addListener("click", (_event) => {
            currentOpennedInfoWindow?.close();
            infowindow.open(googleMap, marker);
            currentOpennedInfoWindow = infowindow;
            infoFiller(item);
          });
        }
      });
    }
  };
  /**
   * Centers the map to the specified coordinates.
   * @param {google.maps.LatLngLiteral} coordinates - The coordinates to center the map to.
   * @returns {void}
   */
  const centerMapTo = (coordinates: google.maps.LatLngLiteral): void => {
    googleMap.setCenter(coordinates);
  };

  watch(
    () => buttons.value,
    (newButtons) => {
      console.log("new in use button", newButtons);
    },
    {
      immediate: true,
      deep: true,
    },
  );

  const clearButtonEvents = () => {
    console.log("buttons", buttons.value);
    buttons.value.forEach((button: HTMLButtonElement) => {
      if (!button) {
        return;
      }

      button.removeEventListener("click", _btnClickFn);
    });
  };

  const forceInfoWindowOpener = (restaurant: RestaurantType) => {
    const refOfRestaurant = infoWindowReferences.find((item) => item.code === restaurant.code);
    if (refOfRestaurant) {
      currentOpennedInfoWindow?.close();
      refOfRestaurant.infoWindow.open(googleMap, refOfRestaurant.marker);
      currentOpennedInfoWindow = refOfRestaurant.infoWindow;
      infoFiller(restaurant);
      recenterMapToInfoWindow();
    }
  };

  return { initializeGoogleMap, centerMapTo, clearButtonEvents, forceInfoWindowOpener };
}
