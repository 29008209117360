<template>
  <Sidebar
    id="search-panel"
    v-model:visible="searchPanelVisible"
    position="top"
    class="w-full"
    :block-scroll="true"
    :style="{
      maxHeight: isFullSearchScreen === true ? '100vh' : '33.75rem',
    }"
  >
    <template #container="{ closeCallback }">
      <div class="container w-full flex flex-column justify-content-start align-content-center">
        <SearchPanelHeader :close-fn="() => closeCallbackWrapper(closeCallback)">
          <template #search-scope>
            <SearchPanelInputBox v-model:search-key="searchModel" :clear-search="clearSearch" />
          </template>
        </SearchPanelHeader>
        <SearchPanelSuggestion
          v-if="isSuggestionVisible"
          :handle-suggestion-click="handleSuggestedItemClick"
          :suggestions="reverseSuggestions"
        />
        <div
          v-else
          class="w-full"
          :style="{
            maxHeight: isFullSearchScreen === true ? '100vh' : '23rem',
          }"
        >
          <SearchPanelResults
            :product-search-results="productSearchResults"
            :clear-search="clearSearch"
          />
        </div>
      </div>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import useSpecialLayoutHandlingForProduct from "~/composables/useSpeacialLayoutHandlingForProduct";
import { useSiteStore } from "~/store/siteStore";
import { useProductSearch } from "~/composables/useProductSearch";

const siteStore = useSiteStore();
const { searchPanelVisible } = storeToRefs(siteStore);
const { isFullSearchScreen } = useSpecialLayoutHandlingForProduct();
const {
  searchModel,
  handleSuggestedItemClick,
  isSuggestionVisible,
  clearSearch,
  productSearchResults,
  searchSuggestions,
  initiateSuggestion,
} = useProductSearch();
const reverseSuggestions = computed(
  () => searchSuggestions.value && searchSuggestions.value.reverse().slice(0, 5),
);
const closeCallbackWrapper = (cb: () => void) => {
  clearSearch();
  cb();
};
watch(
  () => searchPanelVisible.value,
  () => {
    if (searchPanelVisible.value) {
      initiateSuggestion();
    }
  },
);
</script>
