<template>
  <Sidebar
    id="search-panel"
    v-model:visible="navigationMenuVisible"
    position="top"
    class="w-full"
    :block-scroll="true"
    :style="{
      maxHeight: '100vh',
      background: 'var(--primary-primary-main-40)',
    }"
  >
    <template #container>
      <div class="w-full flex flex-column justify-content-start align-content-center">
        <div class="w-full flex justify-content-between align-content-center p-4">
          <img
            src="https://storageaccoutndelydev.blob.core.windows.net/otpwebpage/peppes_brand_logo.CERSH8-x.svg"
            alt="brand_logo"
            loading="eager"
          />
          <div
            class="flex flex-column justify-content-center align-content-center"
            @click="siteStore.toggleNavigationMenu()"
          >
            <IconsCloseButtonIcon path-fill="white" />
          </div>
        </div>
        <div class="flex flex-column justify-content-center align-content-start p-4 gap-1">
          <p class="pop-nav-link" @click="navigetToMenu">{{ $t("menuLink") }}</p>
          <p class="pop-nav-link" @click="navigateToReserve">{{ $t("reserveTableLink") }}</p>
          <div class="w-full flex flex-column justify-content-center align-content-center gap-1">
            <p class="pop-nav-link">{{ $t("aboutLink") }}</p>
            <p class="ml-4 pop-nav-sub-link" @click="navigatTodifferentAbout('/about/story')">
              Historien om Peppes Pizza
            </p>
            <p
              class="ml-4 pop-nav-sub-link"
              @click="navigatTodifferentAbout('/about/vacant-positions')"
            >
              Ledige stillinger
            </p>
            <p
              class="ml-4 pop-nav-sub-link"
              @click="navigatTodifferentAbout('/about/company-at-peppes')"
            >
              Selskap hos peppes
            </p>
            <p
              class="ml-4 pop-nav-sub-link"
              @click="navigatTodifferentAbout('/about/student-discount')"
            >
              Studentrabatt
            </p>
            <p
              class="ml-4 pop-nav-sub-link"
              @click="navigatTodifferentAbout('/about/nutritional-content')"
            >
              Næringsinnhold
            </p>
            <p class="ml-4 pop-nav-sub-link" @click="navigatTodifferentAbout('/about/restaurants')">
              Restauranter
            </p>
          </div>
          <p
            class="ml-4 pop-nav-sub-link"
            @click="navigatTodifferentAbout('/about/privacy-statement')"
          >
            Personvernerklæring
          </p>
          <p class="ml-4 pop-nav-sub-link" @click="navigatTodifferentAbout('/about/business-user')">
            Bedriftskunder
          </p>
          <p class="ml-4 pop-nav-sub-link" @click="navigatTodifferentAbout('/about/feedback')">
            Ris & Ros
          </p>
          <NuxtLink
            @click="
              navigatTodifferentAbout(
                'https://engine.gogift.com/peppesno/product/802114241831034880',
                true,
              )
            "
          >
            <p class="ml-4 pop-nav-sub-link">Gavekort</p>
          </NuxtLink>
        </div>
      </div>
    </template>
  </Sidebar>
</template>

<script setup lang="ts">
import { useSiteStore } from "~/store/siteStore";

const siteStore = useSiteStore();
const { navigationMenuVisible } = storeToRefs(siteStore);
const navigetToMenu = () => {
  navigateTo("/menu");
  siteStore.toggleNavigationMenu();
};
const navigateToReserve = () => {
  window.location.href = "https://www.peppes.no/webshop/bordreservasjon?from=fp";
  siteStore.toggleNavigationMenu();
};
const navigatTodifferentAbout = (link: string, differentSite: boolean = false) => {
  siteStore.toggleNavigationMenu();
  if (!differentSite) {
    navigateTo(link);
  } else {
    window.location.href = link;
  }
};
</script>

<style scoped></style>
