<template>
  <div class="search-panel-input-box">
    <IconsRedSearchIcon />
    <InputText v-model="searchKey" :placeholder="$t('Searchproducts')" />
    <IconsCloseButtonIcon v-if="searchKey" @click="clearSearch(false)" />
  </div>
</template>

<script setup lang="ts">
const searchKey = defineModel<string>("searchKey");
defineProps({
  clearSearch: {
    type: Function,
    default: () => {},
  },
});
</script>

<style scoped></style>
