<template>
  <div class="recommended-item">
    <div class="action-container">
      <NuxtImg
        :src="props.p.img"
        class="recommended-item-image"
        :placeholder="loadingBox"
        alt="recommended-image"
      >
        <template #error>
          <img :src="loadingBox" :alt="props.p.name" class="recommended-item-image" />
        </template>
      </NuxtImg>
      <div class="add-btn" @click="addToCart">
        <i class="pi pi-plus" />
      </div>
    </div>
    <div class="description">
      <p class="title-price">{{ props.p.price }},-</p>
      <p class="subtitle">{{ props.p.name }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import loadingBox from "assets/logo/loading_box.svg";
import { useCartSlice } from "~/store/cartSlice";

const cartStore = useCartSlice();

const props = defineProps({
  p: {
    type: Object,
    default: () => ({}),
  },
});

const addToCart = () => {
  const cartableItem = cartStore.createCartAddableItem(props.p);
  console.log(cartableItem);
  if (cartableItem) {
    useNuxtApp().$firebaseTrackingFunctions.trackSelectRecommendedProduct(cartableItem.productId);
    cartStore.addToCart(cartableItem);
  }
};
</script>
