<template>
  <div
    class="w-full flex flex-column justify-content-start align-items-start h-full"
    :class="productSearchResults.length ? 'overflow-y-scroll' : 'overflow-hidden'"
  >
    <div
      v-for="products in productSearchResults"
      :key="products.subCategoryName"
      class="w-full flex flex-column justify-content-start align-items-start gap-1"
    >
      <p class="sub-category-title my-2 ml-1">{{ products.subCategoryName }}</p>
      <div class="product-row-container one-row">
        <ReusableProductCard
          v-for="product in products.productDetails"
          :key="product.id"
          order="row"
          :product="product"
          :attributes="productAttributeSeparator(product)"
          :show-actions="false"
          @click="handleSearchItemClick(product)"
        />
      </div>
    </div>
    <div
      v-if="!productSearchResults.length"
      class="w-full h-full flex justify-content-center align-items-center gap-1"
    >
      <p>{{ $t("noSearchFound") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductEntity, ProductsResponse } from "~/types/entities/product.type";
import { useMenuSlice } from "~/store/menuSlice";

const props = defineProps({
  productSearchResults: {
    type: Array as PropType<ProductsResponse[]>,
    default: () => [],
  },
  clearSearch: {
    type: Function,
    default: () => {},
  },
});
const router = useRouter();
const menuStore = useMenuSlice();
const handleSearchItemClick = (product: ProductEntity) => {
  router.push({ path: "/menu", query: { product: product.productGroupName } });
  menuStore.setQueryParams("product", product.productGroupName);
  props.clearSearch(true);
};
const productAttributeSeparator = (item: ProductEntity) => {
  const outPut = [];
  item.isVegan && outPut.push("Veg");
  switch (item.spiceLevel) {
    case "High":
      outPut.push("SpicyX3");
      break;
    case "Low":
      outPut.push("SpicyX1");
      break;
    case "Medium":
      outPut.push("SpicyX2");
      break;
  }
  return outPut;
};
</script>
