<template>
  <section class="w-full saved-location">
    <p v-if="userAddressList.length" class="title">{{ $t("savedAddressTitle") }}</p>
    <div class="item-container">
      <LocaitonSavedItem
        v-for="address of userAddressList"
        :key="address.id"
        :address-props="address"
        :is-active="isSelectedFromFavAddress(address.id)"
        @address-selected="handleAddressSelected"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAuthSlice } from "~/store/authSlice";
import { useSelectLocationForDelivery } from "~/composables/useSelectLocationForDelivery";
import type { UserAddress } from "~/types/entities/user.type";

const authStore = useAuthSlice();
const { userAddressList } = storeToRefs(authStore);
const { isSelectedFromFavAddress, mapRestaurantWithAddressAndProceed } =
  useSelectLocationForDelivery();
const handleAddressSelected = (address: UserAddress) => {
  mapRestaurantWithAddressAndProceed(address);
};
onMounted(() => {
  setTimeout(() => {
    authStore.getUserAddressList();
  }, 100);
});
</script>

<style scoped></style>
