<template>
  <div
    v-if="constantTimeAccordingToRestaurant"
    class="location-placeholder"
    @click="orderMethod.toggleWhenModal"
  >
    <div class="flex justify-content-center align-content-center">
      <IconsClockButtonIcon />
    </div>
    <p>{{ status }}</p>
  </div>
</template>

<script setup lang="ts">
import "moment/locale/nb";
import { useI18n } from "#i18n";
import { HandoverType, useOrderMethodStore } from "~/store/orderMethodSlice";
import { dateTimeFormater } from "~/utils/dateTime.util";

const { t } = useI18n();
const orderMethod = useOrderMethodStore();
const { timeOfHandover, constantTimeAccordingToRestaurant } = storeToRefs(orderMethod);
const status = computed(() => {
  return timeOfHandover.value.type === HandoverType.ASSOONASPOSSIBLE
    ? `${t("Assoonaspossible")} (${constantTimeAccordingToRestaurant.value})`
    : dateTimeFormater(timeOfHandover.value.schedule.date, timeOfHandover.value.schedule.time);
});
</script>
