<template>
  <div
    class="w-full minimum-height-30 flex flex-column justify-content-between gap-4 align-items-start"
  >
    <LocaitonSearchAndSelect
      :search-function="searchRestaurants"
      @option-selected="selectedOptionHandler"
    />
    <div class="w-full h-27rem mt-2">
      <LocaitonMap :restaurant-selected="selectedRestaurant" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRestaurants } from "~/composables/api/restaurants/useRestaurants";
import type { RestaurantType } from "~/types/entities/restaurant";

const selectedRestaurant = ref<RestaurantType>();

const { searchRestaurants } = useRestaurants();
const selectedOptionHandler = (restaurant: RestaurantType) => {
  selectedRestaurant.value = restaurant;
};
</script>
<style scoped lang="scss">
.minimum-height-30 {
  min-height: 30rem;
}
</style>
