<template>
  <div
    id="search-box"
    ref="searchBox"
    class="w-full flex flex-row justify-content-center align-items-center relative"
  >
    <InputText
      v-model="searchkey"
      :placeholder="$t('Enterareaorrestaurant')"
      class="w-full"
      @focus="toggle"
    />
    <OverlayPanel
      ref="op"
      class="search-overlay-container"
      :pt="{ root: { parent: 'search-box' } }"
      :style="{
        width: `${searchBoxWidth}px`,
        maxHeight: '15rem',
        overflowY: 'scroll',
      }"
    >
      <div v-for="item of options" :key="item.name" class="search-item" @click="handleSelect(item)">
        <p class="title">{{ item.name }}</p>
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <p class="subtitle">Åpent {{ timeString(item) }}</p>
          <p class="subtitle">{{ item.postalCode }}, {{ item.postalCodeName }}</p>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { OrderType, type RestaurantType } from "~/types/entities/restaurant";

const searchkey = ref<string>("");
const op = ref();
const searchBox = ref();
const searchBoxWidth = ref(100);
const options = ref<RestaurantType[]>([]);
const emit = defineEmits(["option-selected"]);
const props = defineProps({
  searchFunction: {
    type: Function,
    default: (key: string) => key,
  },
});
const search = useDebounceFn(() => searchkey.value && props.searchFunction(searchkey.value), 100);

watch(
  () => [searchBox.value],
  ([newSearchBox]) => {
    searchBoxWidth.value = newSearchBox?.offsetWidth ?? searchBoxWidth.value ?? 100;
  },
);
watch(
  () => searchkey.value,
  async () => {
    options.value = await search();
  },
);

const toggle = (event) => {
  op.value.toggle(event);
};

const timeString = (item: RestaurantType): string => {
  return (
    item?.orderTypeInfo
      .find((i) => i.type === OrderType.TAKEAWAY)
      ?.openingHours?.openingTime.split(":")
      .filter((_i, ind) => ind < 2)
      .join(":") +
    "-" +
    item?.orderTypeInfo
      .find((i) => i.type === OrderType.TAKEAWAY)
      ?.openingHours?.closingTime.split(":")
      .filter((_i, ind) => ind < 2)
      .join(":")
  );
};
const handleSelect = (item: RestaurantType) => {
  op.value.hide();
  emit("option-selected", item);
};
</script>
