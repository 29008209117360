<template>
  <div class="customization">
    <p class="header">Deling av data</p>
    <div
      id="technical"
      class="w-full flex flex-column justify-content-start align-content-start gap-2"
    >
      <p class="title">Nødvendig</p>
      <p class="sub-title">
        Disse er nødvendig for at appen skal fungere når du navigerer rundt, og kan derfor ikke
        velges bort.
      </p>
      <!--      <VueToggles-->
      <!--        v-model="localEmergencyConsent"-->
      <!--        :width="40"-->
      <!--        checked-bg="#CD1719"-->
      <!--        :disabled="true"-->
      <!--      />-->
      <InputSwitch v-model="localEmergencyConsent" :disabled="true" />
    </div>
    <div
      id="technical"
      class="w-full flex flex-column justify-content-start align-content-start gap-2"
    >
      <p class="title">Analyse</p>
      <p class="sub-title">
        Disse hjelper oss med å vise deg mer personlig og relevant innhold i annonser, samt for å
        analysere brukermønstre.
      </p>
      <!--      <VueToggles v-model="localAnalysisConsentGoogle" :width="40" checked-bg="#CD1719" />-->
      <InputSwitch v-model="localAnalysisConsentGoogle" />
    </div>
    <div
      id="technical"
      class="w-full flex flex-column justify-content-start align-content-start gap-2"
    >
      <p class="title">Feilrapportering</p>
      <p class="sub-title">Disse er for bruk til feilretting av systemet.</p>
      <!--      <VueToggles v-model="localErrorReportingConsentToSentry" :width="40" checked-bg="#CD1719" />-->
      <InputSwitch v-model="localErrorReportingConsentToSentry" />
    </div>
    <div class="w-full flex justify-content-center align-content-center">
      <Button class="active" @click="customizeConsentAccept">Lagre</Button>
    </div>
    <p class="muted_title w-full text-center">
      Peppes er opptatt av ditt brukervern og vi behandler personopplysningene dine i tråd med
      gjeldene lover og regler.
    </p>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["toggle-analysisConsentGoogle", "toggle-errorReportingConsentToSentry"]);
const props = defineProps({
  emergencyConsent: {
    type: Boolean,
    default: true,
  },
  analysisConsentGoogle: {
    type: Boolean,
    default: false,
  },
  errorReportingConsentToSentry: {
    type: Boolean,
    default: false,
  },
  customizeConsentAccept: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
});
const localEmergencyConsent = computed(() => {
  return props.emergencyConsent;
});
const localAnalysisConsentGoogle = computed({
  get() {
    return props.analysisConsentGoogle;
  },
  set(value: boolean) {
    emit("toggle-analysisConsentGoogle", value);
  },
});
const localErrorReportingConsentToSentry = computed({
  get() {
    return props.errorReportingConsentToSentry;
  },
  set(value: boolean) {
    emit("toggle-errorReportingConsentToSentry", value);
  },
});
</script>

<style scoped></style>
