<template>
  <div
    class="w-full minimum-height-30 flex flex-column justify-content-between gap-4 align-items-start"
  >
    <div class="w-full">
      <LocaitonPlaceSearch />
    </div>
    <div
      :class="`w-full flex-grow-1 h-full flex flex-column ${selectedLocation && !selectedLocation.id ? '' : 'mt-5'} justify-content-between align-items-start gap-4`"
    >
      <div
        v-if="selectedLocation && !selectedLocation.id"
        class="w-full flex flex-row justify-content-between align-items-center"
      >
        <div class="flex flex-row justify-content-start align-items-center gap-4">
          <div>
            <img :src="locationMark" alt="location-mark" />
          </div>
          <div class="flex flex-column justify-content-center align-items-start gap-2">
            <p>{{ selectedLocation.adressetekst }}</p>
            <p>{{ selectedLocation.poststed }}, {{ " " + selectedLocation.postnummer }}</p>
          </div>
        </div>

        <div class="p-2 mr-2">
          <i class="pi pi-check" style="font-size: 2rem; color: green"></i>
        </div>
      </div>
      <div v-else></div>
      <div class="w-full">
        <div
          v-if="!authStatus"
          class="flex flex-row justify-content-start align-items-center"
          @click="popLoginUp"
        >
          <IconsBurningUserIcon />
          <p class="text-red-600 cursor-pointer">{{ $t("Loginforsavedaddresses") }}</p>
        </div>
        <LocaitonSaved v-else />
        <Button class="w-full mt-3" @click="locationStore.toggleLocationSelectionModal">
          {{ $t("Confirm") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLocationSlice } from "~/store/locationSlice";
import { useOrderMethodStore } from "~/store/orderMethodSlice";
import locationMark from "assets/logo/location-marker.svg";
import { useAuthSlice } from "~/store/authSlice";

const locationStore = useLocationSlice();
const orderMethodeStore = useOrderMethodStore();
const { selectedLocation } = storeToRefs(orderMethodeStore);
const authStore = useAuthSlice();
const { isAuthenticated: authStatus } = storeToRefs(authStore);
const popLoginUp = () => {
  authStore.toggleAuthModal();
  locationStore.toggleLocationSelectionModal();
};
</script>
<style scoped lang="scss">
.minimum-height-30 {
  min-height: 30rem;
}
</style>
