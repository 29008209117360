<template>
  <div class="item-layout" @click="clickHandler(addressProps)">
    <img src="~/assets/logo/Location-1.svg" alt="marker" />
    <div class="info-container">
      <div class="info">
        <p class="title" :class="isActive ? 'active' : ''">{{ title(addressProps) }}</p>
        <p class="sub-title">
          {{ addressProps?.streetName }}, {{ addressProps?.streetNumber }},
          {{ addressProps?.postalCode }} {{ addressProps?.postalAddress }}
        </p>
      </div>
      <div
        v-if="isActive"
        class="flex flex-column justify-content-center align-content-center h-full"
      >
        <div class="active-mark">
          <i class="pi pi-check text-green-900" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UserAddress } from "~/types/entities/user.type.js";

defineProps({
  addressProps: {
    type: Object as PropType<UserAddress | null>,
    default() {
      return {};
    },
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["address-selected"]);
const clickHandler = (address: UserAddress | null) => {
  if (address) {
    emit("address-selected", address);
  }
};
const title = (address: UserAddress | null) => {
  if (!address) {
    return "";
  }
  if (address.addressType) {
    return address.addressType;
  } else {
    return address.streetName + " " + (address.streetNumber || "") + (address.stairWay || "");
  }
};
</script>

<style lang="scss" scoped></style>
