<template>
  <client-only>
    <Dialog
      v-model:visible="isVisible"
      modal
      :style="{ width: '37.375rem' }"
      :pt="{
        root: 'border-none',
        mask: {
          style: 'backdrop-filter: blur(2px)',
        },
      }"
    >
      <template #container>
        <Card class="w-full overflow-auto" :style="{ background: 'rgba(255, 253, 249, 0.94)' }">
          <template #content>
            <div class="consent_layout_Container">
              <component
                :is="activePageComponent"
                :navigate-to-customization="navigateToCustomization"
                :emergency-consent="emergencyConsent"
                :analysis-consent-google="analysisConsentGoogle"
                :error-reporting-consent-to-sentry="errorReportingConsentToSentry"
                :accept-all-consent="acceptAllConsent"
                :customize-consent-accept="customizeConsentAccept"
                @toggle-analysis-consent-google="toggleAnalysisConsentGoogle"
                @toggle-error-reporting-consent-to-sentry="toggleErrorReportingConsentToSentry"
              />
            </div>
          </template>
        </Card>
      </template>
    </Dialog>
  </client-only>
</template>

<script setup lang="ts">
import useConsentTracking, { PageTypes } from "~/composables/useConsentTracking";
import { useSiteStore } from "~/store/siteStore";

const siteStore = useSiteStore();
const ConsentLanding = resolveComponent("ConsentLanding");
const ConsentCustomization = resolveComponent("ConsentCustomization");
const {
  isVisible,
  currentPage,
  navigateToCustomization,
  emergencyConsent,
  analysisConsentGoogle,
  errorReportingConsentToSentry,
  customizeConsentAccept,
} = useConsentTracking();
const activePageComponent = computed(() => {
  if (currentPage.value === PageTypes.LandingPage) {
    return ConsentLanding;
  }
  if (currentPage.value === PageTypes.CUSTOMIZER) {
    return ConsentCustomization;
  }
});
const toggleAnalysisConsentGoogle = (val: boolean) => {
  siteStore.toggleAnalysisConsentGoogle(val);
};
const toggleErrorReportingConsentToSentry = (val: boolean) => {
  siteStore.toggleErrorReportingConsentToSentry(val);
};
const acceptAllConsent = () => {
  customizeConsentAccept();
};
</script>
