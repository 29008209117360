<template>
  <div class="w-full h-full">
    <div id="map" ref="mapElement" class="h-full w-full"></div>
    <div style="display: none">
      <div ref="popRef" class="infowindow-container">
        <p class="title">Peppes Tåsen</p>
        <div class="w-full flex justify-content-between gap-6 align-content-center">
          <div class="flex align-content-center gap-1">
            <span class="status-icon"></span>
            <p class="status"></p>
          </div>
          <p class="subtitle">Åpent 11:00–23:00</p>
        </div>
        <Button class="w-full">{{ $t("Select") }}</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useGoogleMap from "~/composables/gmap/useGoogleMap";
import type { RestaurantType } from "~/types/entities/restaurant";
import { useLocationSlice } from "~/store/locationSlice";

const props = defineProps({
  restaurantSelected: {
    type: Object as () => RestaurantType | null,
    default: null,
  },
});
const locationStore = useLocationSlice();

const popRef = ref();

const mapElement = ref();

const btnClickFn = (_event: MouseEvent, item: RestaurantType | null) => {
  locationStore.setSelectedRestaurant(item);
  locationStore.toggleLocationSelectionModal();
};

const { initializeGoogleMap, clearButtonEvents, forceInfoWindowOpener } = useGoogleMap(btnClickFn);
watch(
  () => props.restaurantSelected,
  () => {
    if (props.restaurantSelected) {
      forceInfoWindowOpener(props.restaurantSelected);
    }
  },
);
onMounted(() => {
  initializeGoogleMap(mapElement, popRef.value);
});
onUnmounted(() => {
  clearButtonEvents();
});
</script>
