<template>
  <Card class="payment-methods-container">
    <template #content>
      <div class="w-full flex justify-content-end align-items-center cursor-pointer">
        <IconsCloseButtonIcon @click="props.backToMethods" />
      </div>
      <p class="header">Payment Option</p>
      <div
        class="w-full h-full flex flex-column justify-content-start align-items-center gap-4 mt-5"
      >
        <div
          v-if="!doseVippsExists"
          class="w-full flex justify-content-start align-items-center gap-4 cursor-pointer"
          @click="VippsAsPaymentMethods"
        >
          <img :src="vippsLogo" style="width: 34px" alt="logo" />
          <p>VIPPS</p>
        </div>
        <div
          class="w-full flex justify-content-start align-items-center gap-4 cursor-pointer"
          @click="addSwedBank"
        >
          <img :src="cardIcon" alt="logo" />
          <p>SWEDBANK</p>
        </div>
      </div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import vippsLogo from "assets/logo/vipps-small.svg";
import cardIcon from "assets/logo/credit-card.svg";
import usePaymentMethodsApi from "~/composables/api/usePaymentMethodsApi";
import { useAuthSlice } from "~/store/authSlice";

const { registerCardInAccount } = usePaymentMethodsApi();
const authStore = useAuthSlice();
const { user, paymentCards } = storeToRefs(authStore);

const props = defineProps({
  backToMethods: {
    type: Function,
    default: () => {},
  },
  addSwedBank: {
    type: Function,
    default: () => {},
  },
});
const doseVippsExists = computed(() => {
  return paymentCards.value.find((it) => it.provider === "Vipps");
});
const VippsAsPaymentMethods = async () => {
  if (user.value && user.value.sub) {
    await registerCardInAccount(user.value.sub, "Vipps");
    await authStore.getUserPaymentCardsInfo();
    props.backToMethods();
  }
};
const addSwedBank = () => {
  props.backToMethods();
  props.addSwedBank();
};
</script>
