<template>
  <ReusableModal
    :visible="authModalVisible"
    class="peppes-auth-methods-modal"
    @on-hidden="resetScreen"
    @dialog-toggle="authStore.toggleAuthModal"
  >
    <div class="peppes-auth-methods-container">
      <div class="greetings-container mb-2">
        <p class="greetings-title pb-1">{{ $t("Welcome") }}</p>
      </div>
      <div class="w-full flex flex-column justify-content-center align-items-center gap-3">
        <ReusableAuthMethodsVipps />
        <ReusableDivider :content="$t('OrloginwithSMS')" />
        <ReusableAuthMethodsPhoneNumber @check-for-otp-verification="otpScreen = true" />
      </div>
      <!--      <div v-else id="otp-screen">-->
      <!--        <ReusableAuthMethodsPhoneNumberOtpverifier />-->
      <!--      </div>-->
    </div>
  </ReusableModal>
</template>

<script setup lang="ts">
import { useAuthSlice } from "~/store/authSlice";

const { logGoogleEventWithMiddleWare } = useConsentTracking();
const authStore = useAuthSlice();
const { authModalVisible } = storeToRefs(authStore);
const otpScreen = ref<boolean>(false);

const resetScreen = () => {
  otpScreen.value = false;
};
watch(
  () => authModalVisible.value,
  () => {
    if (authModalVisible.value) {
      logGoogleEventWithMiddleWare("screen_view", { screen: "Auth Modal" });
    }
  },
);
</script>
<style lang="scss"></style>
