<template>
  <div
    class="flex flex-row justify-content-center align-content-center gap-3 cursor-pointer"
    @click="cartStore.toggleCartShow"
  >
    <div class="relative px-1 hidden md:flex">
      <Button size="small" text type="button">
        <IconsCartbuttonicon />
      </Button>
      <Badge v-if="cartLength" :value="cartLength" class="cart-badge"></Badge>
    </div>
    <div v-if="cartLength && isMenuPage" class="cart-price hidden md:flex">
      <p class="title">{{ $t("Gotocheckout") }}</p>
      <p class="title">{{ cartTotalPrice + " " }}kr</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartSlice } from "~/store/cartSlice";

const route = useRoute();
const cartStore = useCartSlice();
const { cartLength, cartTotalPrice } = storeToRefs(cartStore);
const isMenuPage = computed(() => {
  return route.path === "/menu";
});
</script>
