<template>
  <div class="carted-items">
    <div v-for="(item, index) of cartList" :key="item.productId + index" class="w-full">
      <ShoppingCartItem v-if="!item.product.isDeal" :item-props="item" :index="index" />
      <ShoppingCartDealItem
        v-else
        :sub-product="item.product.customizableOptions.includedItem"
        :is-available="item.product.isAvailable"
      >
        <ShoppingCartItem :item-props="item" :index="index" />
      </ShoppingCartDealItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartSlice } from "~/store/cartSlice";

const cartStore = useCartSlice();
const { cartList } = storeToRefs(cartStore);
</script>
