import { useOrderMethodStore } from "~/store/orderMethodSlice";
import type { UserAddress } from "~/types/entities/user.type";
import { useRestaurants } from "~/composables/api/restaurants/useRestaurants";
import { useLocationSlice } from "~/store/locationSlice";
import type { PlaceRecommendation } from "~/types/entities/placeRecommendation.type";

export function useSelectLocationForDelivery() {
  const orderMethod = useOrderMethodStore();
  const { getRestaurantsByLatLn } = useRestaurants();
  const { selectedLocation } = storeToRefs(orderMethod);
  const locationStore = useLocationSlice();
  const isSelectedFromFavAddress = (id: number) => {
    return !!selectedLocation.value && selectedLocation.value.id === id;
  };
  const mapRestaurantWithAddressAndProceed = async (address: UserAddress) => {
    const payload: Partial<PlaceRecommendation> = {
      id: address.id,
      adressenavn: address.streetName,
      nummer: address.streetNumber,
      postnummer: address.postalCode,
      bokstav: address.stairWay,
      adressetekst:
        address.streetName + " " + `${address.streetNumber || "" + address.stairWay || "" || ""}`,
      poststed: address.postalAddress,
      floor: address.floor,
      instruction: address.additionalInfo,
    };
    const restaurant = await getRestaurantsByLatLn(payload);
    if (restaurant) {
      console.log(restaurant);
      orderMethod.setSelectedLocation(payload);
      orderMethod.setSelectedRestaurantForDelivery(restaurant);
      locationStore.toggleLocationSelectionModal();
    }
  };
  return {
    isSelectedFromFavAddress,
    mapRestaurantWithAddressAndProceed,
  };
}
