<template>
  <div class="search-panel-header" :class="isFullSearchScreen ? 'phone' : ''">
    <div>
      <img
        v-if="!isFullSearchScreen"
        src="https://storageaccoutndelydev.blob.core.windows.net/otpwebpage/peppes_brand_logo.CERSH8-x.svg"
        alt="brand_logo"
        loading="eager"
      />
    </div>
    <slot v-if="!isFullSearchScreen" name="search-scope"></slot>
    <div class="close-icon">
      <IconsCloseButtonIcon @click="closeFn" />
    </div>
    <div v-if="isFullSearchScreen" class="w-full">
      <slot name="search-scope" class="w-full"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isFullSearchScreen } = useSpeacialLayoutHandlingForProduct();
defineProps({
  closeFn: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
});
</script>
