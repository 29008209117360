<template>
  <div class="nav-actions">
    <client-only>
      <Button
        v-if="validLocationRoute"
        size="small"
        text
        type="button"
        class="px-1"
        @click="siteStore.toggleSearchPanel"
      >
        <IconsSearchButtonIcon />
      </Button>
      <ReusableAuth class="bg-transparent" />
      <HeaderNavbarCartIcon v-if="validLocationRoute" />
      <div class="lg:hidden" @click="siteStore.toggleNavigationMenu()">
        <IconsMenuButtonIcon />
      </div>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { useSiteStore } from "~/store/siteStore";

const excludedRoute = ["/", "/checkout", "/profile"];
const siteStore = useSiteStore();
const route = useRoute();
const validLocationRoute = computed(() => {
  return !excludedRoute.includes(route.path);
});
</script>
