<template>
  <Button
    class="user-icon-space"
    v-bind="$attrs"
    aria-haspopup="true"
    aria-controls="overlay_menu"
    size="small"
    text
    type="button"
    @click="popUpAuthDialog"
  >
    <IconsUserButtoonIcon />
    <IconsArrowDownIcon v-if="authStatus" />
  </Button>
  <Menu id="overlay_menu" ref="menu" :model="items" :popup="true">
    <template #item="{ item, props }">
      <a v-ripple class="flex align-items-center" v-bind="props.action">
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </template>
  </Menu>
</template>

<script setup lang="ts">
import useVippsAuth from "~/composables/auth/useVippsAuth";
import { useAuthSlice } from "~/store/authSlice";

const authStore = useAuthSlice();
const menu = ref();
const { isAuthenticated: authStatus } = storeToRefs(authStore);
const { signOut } = useVippsAuth();
const items = ref([
  {
    label: "Profil",
    command: () => navigateTo("/profile"),
  },
  {
    label: "Logg ut",
    command: () => {
      try {
        signOut();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
]);
const popUpAuthDialog = (event: MouseEvent) => {
  if (!authStatus.value) {
    authStore.toggleAuthModal();
  } else {
    menu.value.toggle(event);
  }
};
onMounted(() => {
  setTimeout(() => {
    authStore.reviseAuthState();
  }, 100);
});
</script>
