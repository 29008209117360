export enum OrderType {
  DINEIN = "DINEIN",
  TAKEAWAY = "TAKEAWAY",
  DELIVERY = "DELIVERY",
}

export interface OrderTypeInfo {
  type: OrderType;
  code: string;
  deliveryTime: number;
  openingHours: {
    day: string;
    openingTime: string;
    closingTime: string;
  };
  openingHoursOfTheWeek: {
    day: string;
    openingTime: string;
    closingTime: string;
  }[];
  supportedOrderType: boolean;
  deviatingOpeningHours?: {
    date: string;
    openingHours: {
      day: string;
      openingTime: string;
      closingTime: string;
    };
  }[];
}

export interface RestaurantType {
  code: string;
  name: string;
  longitude: number;
  latitude: number;
  orderTypeInfo: OrderTypeInfo[];
  address: string;
  postalCode: string;
  postalCodeName: string;
}
