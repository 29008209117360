<template>
  <div>
    <div v-if="showHeader" class="custom-header">
      <div class="header-content">
        <div class="centered-text">
          Bedriftskunde? Vi har oppdatert hvordan du utfører bestillinger!
        </div>
        <NuxtLink to="/about/business-user">
          <button class="rounded-button">Sjekk det her</button>
        </NuxtLink>
      </div>
      <button class="close-button" @click="closeHeader">×</button>
    </div>
    <section id="homePageLayout" class="container">
      <ConfirmDialog :draggable="false"></ConfirmDialog>
      <header id="header">
        <HeaderNavigationMenu />
        <SearchPanel />
        <ShoppingCart />
        <Header />
      </header>
      <main id="pageContent">
        <slot />
      </main>
      <!--  common modals-->
      <ReusableAuthMethods />
      <LocaitonLocationsSelector />
      <CheckoutWhenToDeliveryModal />
      <PaymentMethods />
      <Consent />
    </section>
  </div>
</template>

<script setup lang="ts">
import { useLocationSlice } from "~/store/locationSlice";
import { useAuthSlice } from "~/store/authSlice";
import { useOrderMethodStore } from "~/store/orderMethodSlice";
import { useMenuSlice } from "~/store/menuSlice";

const authStore = useAuthSlice();
const orderMethodStore = useOrderMethodStore();
const { isAuthenticated } = storeToRefs(authStore);
const { coords } = useGeolocation();
const locationStore = useLocationSlice();
const route = useRoute();
const menuStore = useMenuSlice();
watch(
  () => coords.value,
  () => {
    locationStore.setCurrentUserLocation(coords.value.latitude, coords.value.longitude);
  },
);
watch(
  () => isAuthenticated.value,
  () => {
    if (isAuthenticated.value) {
      setTimeout(() => {
        authStore.getUserDataIfLoggedIn();
        orderMethodStore.fetchAllLiveOrder();
      }, 1000);
    }
  },
  { immediate: true },
);
watch(
  () => route.path,
  () => {
    if (route.path === "/menu" && isAuthenticated.value) {
      setTimeout(() => {
        orderMethodStore.fetchAllLiveOrder();
      }, 1000);
    }
  },
  { immediate: true },
);
onMounted(() => {
  setTimeout(() => {
    menuStore.getAllRestaurants();
  }, 100);
  // useNuxtApp().$Sentry.captureException("test exception"); ->to test consent sentry
});
const showHeader = ref(true);

const closeHeader = () => {
  showHeader.value = false;
};
</script>

<style lang="scss" scoped>
.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #5b0a0b;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.centered-text {
  font-weight: bold;
  margin-right: 20px;
  color: azure;
}

.rounded-button {
  padding: 5px 15px;
  border-radius: 20px;
  border: none;
  background-color: #ff9395;
  color: #5b0a0b;
  cursor: pointer;
}

.close-button {
  background: none;
  color: #fcf1f1;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
</style>
