<template>
  <ReusableModal
    :visible="locationSelectionModalShow"
    :style="{ width: '35.5rem' }"
    :dismissable="false"
    @dialog-toggle="locationStore.toggleLocationSelectionModal"
  >
    <div class="w-full flex flex-column justify-content-start align-items-start gap-3">
      <p class="peppes-headline">{{ modalTitle }}</p>
      <div class="location-selection-options">
        <Button
          rounded
          :class="`${selectedMethod === OrderMethod.PICKUP ? 'active' : ''}`"
          @click="orderMethodStore.setOrderMethod(OrderMethod.PICKUP)"
        >
          {{ $t("Pickupyourself") }}
        </Button>
        <Button
          rounded
          :class="`${selectedMethod === OrderMethod.DELIVERY ? 'active' : ''}`"
          @click="orderMethodStore.setOrderMethod(OrderMethod.DELIVERY)"
        >
          {{ $t("Delivery") }}
        </Button>
      </div>
      <LocaitonForSelf v-if="selectedMethod === OrderMethod.PICKUP" />
      <LocaitonForDelivery v-else />
    </div>
  </ReusableModal>
</template>

<script setup lang="ts">
import { useLocationSlice } from "~/store/locationSlice";
import { OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";

const { t } = useI18n();
const orderMethodStore = useOrderMethodStore();
const { selectedMethod } = storeToRefs(orderMethodStore);
const locationStore = useLocationSlice();
const { locationSelectionModalShow } = storeToRefs(locationStore);
const modalTitle = computed(() => {
  return selectedMethod.value === OrderMethod.PICKUP ? t("selectRestaurant") : t("selectLocation");
});
watch(
  () => locationSelectionModalShow.value,
  (newVal) => {
    if (newVal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  },
);
</script>
