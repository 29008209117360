<template>
  <header class="peppes-header">
    <nav>
      <HeaderNavbar v-if="validRouteForBrand" />
    </nav>
    <aside>
      <HeaderLocationSelector v-if="validLocationRoute" />
    </aside>
  </header>
</template>

<script setup lang="ts">
const { width } = useWindowSize();

const excludedRoute = [
  "/",
  "/checkout",
  "/profile",
  "/about",
  "/about/story",
  "/about/vacant-positions",
  "/about/company-at-peppes",
  "/about/student-discount",
  "/about/nutritional-content",
  "/favorite-orders",
  "/order-history",
  "/my-address",
  "/payment-methods",
  "/order-confirmation",
  "/about/privacy-statement",
  "/order-details/*",
  "/about/business-user",
  "/about/feedback",
];
const excludeBrandHeader = ["/checkout", "/order-confirmation"];
const route = useRoute();
const validLocationRoute = computed(() => {
  if (route.path.startsWith("/order-details/")) {
    return false;
  }
  return !excludedRoute.includes(route.path);
});
const validRouteForBrand = computed(() => {
  return !(excludeBrandHeader.includes(route.path) && width.value < 500);
});
</script>
