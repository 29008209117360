<template>
  <div class="nav-brand">
    <NuxtLink :to="localPath('/')">
      <img
        src="https://storageaccoutndelydev.blob.core.windows.net/otpwebpage/peppes_brand_logo.CERSH8-x.svg"
        alt="brand_logo"
        loading="eager"
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const localPath = useLocalePath();
</script>
