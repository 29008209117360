<template>
  <div class="w-full">
    <div class="w-full flex justify-content-between items-center">
      <h3 class="recommendation-title">{{ $t("Recommendedwith") }}</h3>
      <div class="flex flex-row justify-content-start align-items-center gap-2">
        <div class="prev-next-btn" @click="moveLeft">
          <i class="pi pi-arrow-left" />
        </div>
        <div class="prev-next-btn" @click="moveRight">
          <i class="pi pi-arrow-right" />
        </div>
      </div>
    </div>
    <ClientOnly>
      <div class="carousel-wrapper">
        <PeppesCarousel ref="myCarousel" v-bind="config">
          <PeppesSlide v-for="slide of recommendedProducts" :key="slide.id">
            <ShoppingCartSliderItem :p="slide" />
          </PeppesSlide>
        </PeppesCarousel>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import "vue3-carousel/dist/carousel.css";
import { useMenuSlice } from "~/store/menuSlice";

const myCarousel = ref(null);
const menuStore = useMenuSlice();
const { recommendedProducts } = storeToRefs(menuStore);
const config = {
  height: 200,
  gap: 16,
  snapAlign: "start",
  itemsToShow: "auto",
  breakpointMode: "viewport",
};
const moveLeft = () => {
  myCarousel.value.prev();
};
const moveRight = () => {
  myCarousel.value.next();
};
</script>
