<template>
  <Dialog
    v-model:visible="paymentMethodsPopUp"
    modal
    dismissable-mask
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
  >
    <template #container="{ closeCallback }">
      <PaymentMethodsAddOptions
        v-if="addCard"
        :back-to-methods="switchPaymentMood"
        :add-swed-bank="verifyForAdding"
      />
      <Card v-else class="payment-methods-container">
        <template #content>
          <div class="w-full flex justify-content-end align-items-center cursor-pointer">
            <IconsCloseButtonIcon @click="closeCallback" />
          </div>
          <div class="w-full flex flex-column justify-content-start align-items-start gap-2">
            <p class="header">{{ $t("Methodofpayment") }}</p>
            <div class="w-full h-15rem overflow-y-scroll">
              <PaymentMethodsItems
                v-for="item of methodsToShow"
                :key="item.cardId"
                :logo-type="item.logoType"
                :title="item.title"
                :sub-title="item.subTitle"
                :card-id="item.cardId"
                :status="item.status"
                @click="switchPaymentMethod(item.cardId)"
              />
            </div>
            <div class="add-card-button cursor-pointer" @click="switchPaymentMood">
              <i class="pi pi-plus" :style="{ color: 'rgba(205, 23, 25, 1)' }" />
              <p>{{ $t("Addnewpaymentcard") }}</p>
            </div>
          </div>
        </template>
      </Card>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { PaymentUserType, useOrderMethodStore } from "~/store/orderMethodSlice";
import usePaymentMethodsApi from "~/composables/api/usePaymentMethodsApi";
import { useSiteStore } from "~/store/siteStore";
import { useAuthSlice } from "~/store/authSlice";

const methodsStore = useOrderMethodStore();
const siteStore = useSiteStore();
const authStore = useAuthSlice();
const methodStore = useOrderMethodStore();
const addCard = ref(false);
const route = useRoute();
const { paymentMethodsPopUp, selectedPaymentMethod, paymentUserType } = storeToRefs(methodsStore);
const { verifyBeforeAddingCard } = usePaymentMethodsApi();
const { paymentCards } = storeToRefs(authStore);
const methodsToShow = computed(() => {
  return paymentCards.value
    .map((item) => {
      return {
        logoType:
          item.provider === "Swedbank"
            ? "CARD"
            : item.provider === "Vipps"
              ? "VIPPS"
              : item.provider,
        title: item.provider === "Vipps" ? "VIPPS" : item.cardBrand || item.provider,
        subTitle: item.maskedCardNumber,
        status: item.id === selectedPaymentMethod.value?.id,
        cardId: item.id,
        provider: item.provider,
      };
    })
    .filter((card) => {
      return !(paymentUserType.value === PaymentUserType.PERSONAL && card.provider === "INVOICE");
    });
});
const verifyForAdding = async () => {
  siteStore.setRedirectAction(route.path);
  const url = await verifyBeforeAddingCard();
  methodStore.togglePaymentMethodsModal();
  if (url) {
    window.location.href = url;
  }
};
const switchPaymentMood = () => {
  addCard.value = !addCard.value;
};
const switchPaymentMethod = (id: string) => {
  methodStore.setPaymentMethodToPay(id);
  methodsStore.togglePaymentMethodsModal();
};
watch(
  () => paymentMethodsPopUp.value,
  () => {
    if (paymentMethodsPopUp.value) {
      authStore.getUserPaymentCardsInfo();
    }
  },
);
</script>
