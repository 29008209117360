import type { ProductsResponse } from "~/types/entities/product.type";
import useMenu from "~/composables/api/mock/useMenu";

export function useProductSearch() {
  const { searchProducts } = useMenu();
  const productSearchResults = ref<ProductsResponse[]>();
  const searchTerm = ref<string>("");
  const searchSuggestions = ref<string[]>();
  const searchModel = computed({
    get() {
      return searchTerm.value;
    },
    set(val: string) {
      if (val.length <= 40) {
        searchTerm.value = val;
      }
    },
  });
  const handleSuggestedItemClick = (val: string) => {
    searchModel.value = val;
  };
  const isSuggestionVisible = computed(() => {
    return !searchModel.value;
  });
  const clearSearch = (onlyStore = false) => {
    if (searchModel.value) {
      storeSuggestion(searchModel.value);
    }
    if (!onlyStore) {
      searchModel.value = "";
      productSearchResults.value = [];
    }
  };

  const debouncedSearch = useDebounceFn(async () => {
    if (searchModel.value.length > 2) {
      productSearchResults.value = await searchProducts(searchModel.value);
      if (productSearchResults.value.length > 0) {
        useNuxtApp().$firebaseTrackingFunctions.trackSearch(
          searchModel.value,
          productSearchResults.value.length,
        );
      }
    }
  }, 500);
  watch(
    () => searchModel.value,
    () => {
      debouncedSearch(); // Call the debounced function here
    },
  );
  const storeSuggestion = (key: string) => {
    const suggestionsString = localStorage.getItem("search-suggestions");
    const suggestions: Set<string> = suggestionsString
      ? new Set(JSON.parse(suggestionsString))
      : new Set([]);
    suggestions.add(key);
    searchSuggestions.value = Array.from(suggestions);
    localStorage.setItem("search-suggestions", JSON.stringify(Array.from(suggestions)));
  };
  const readSuggestionsFromLocalStorage = () => {
    if (!process.server) {
      const suggestionsString = localStorage.getItem("search-suggestions");
      return suggestionsString ? JSON.parse(suggestionsString) : [];
    }
    return [];
  };
  const initiateSuggestion = () => {
    searchSuggestions.value = readSuggestionsFromLocalStorage();
  };
  return {
    searchModel,
    handleSuggestedItemClick,
    isSuggestionVisible,
    clearSearch,
    productSearchResults,
    storeSuggestion,
    searchSuggestions,
    initiateSuggestion,
  };
}
