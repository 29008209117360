<template>
  <div class="vipps-auth-button-container" @click="vippsLogin">
    <p>{{ $t("Carryon") }}</p>
    <img :src="vippsLogo" alt="vipps-logo" />
  </div>
</template>

<script setup lang="ts">
import useVippsAuth from "~/composables/auth/useVippsAuth";
import vippsLogo from "~/assets/logo/vipps-rgb-orange-pos.svg";
import { useAuthSlice } from "~/store/authSlice";

const authStore = useAuthSlice();
const { signIn } = useVippsAuth();
const vippsLogin = () => {
  useNuxtApp().$firebaseTrackingFunctions.trackLogin("attempt");
  signIn();
  authStore.toggleAuthModal();
};
// const vippsLogout = () => {
//   signOut();
// };
</script>
<style scoped lang="scss"></style>
